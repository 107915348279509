import * as React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { Container, Row, Col, Form, Button } from 'react-bootstrap'

import { StaticImage } from "gatsby-plugin-image"

const SecondPage = () => (
  <Layout>
    <Seo title="Contact Us" />
    <h1 className="text-center py-3">Contact Thomas Tripolt and All Electric</h1>
    <Container>
      <Row>
        <Col lg={5}>
          <p>Main: (415) 336-7968</p>
          <p>Email: <a href="mailto:tt.allelectric@gmail.com">tt.allelectric@gmail.com</a></p>
          <p>Address: 16 Stadium Way, Kentfield, CA 94904</p>
          <p>California License: C10-525460</p>
          <hr />
          <p>Bonded & Insured</p>
          <p>Contractors Licensing Board</p>
        </Col>
        <Col lg={7}>
        <Form 
                        name="contact us"
                        method="post"
                        action="/success/"
                        data-netlify="true"
                        data-netlify-honeypot="bot-field"
                        onSubmit="submit"
                        className="light-border"
                    >
                    {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                    <input type="hidden" name="form-name" value="contact us" />
                    <p hidden>
                    <label>
                        Don’t fill this out: <input name="bot-field" />
                    </label>
                    </p>
                     


                        <Row>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>First Name</Form.Label>
                                    <Form.Control 
                                        type="text" name="first-name"
                                     />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                            <Form.Group>
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control 
                                       type="text" name="last-name"

                                     />
                            </Form.Group>
                            </Col>
                        </Row>


                            
                        <Form.Group>
                            <Form.Label>Email address</Form.Label>
                            <Form.Control 
                                required
                                type="email" 
                                name="email" 
                            />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Phone</Form.Label>
                            <Form.Control 
                                required
                                type="text" 
                                name="phone" 
                            />
                        </Form.Group>
                        
                        <Form.Group>
                            <Form.Label>How can I help you?</Form.Label>
                            <Form.Control 
                                as="textarea" 
                                name="how-can-I-help-you"
                                rows="3"
                             />
                        </Form.Group>
                        <Button type="submit">Submit</Button>
                        </Form>
        </Col>
      </Row>
      <hr />
    </Container>
    <Container>
      <Row>
        <Col lg={5}>
          <p><strong>Recommended Manufacturers</strong></p>
          <ul className="list-unstyled">
            <li><a href="http://lutron.com/">Lutron</a></li>
            <li><a href="http://leviton.com/">Leviton</a></li>
            <li><a href="http://schneider-electric.com/">Schneider-Electric</a></li>
            <li><a href="http://smarthome.com/">Smarthome</a></li>
            <li><a href="http://www.icrealtime.com/">IC Realtime</a></li>
          </ul>
          <p><strong>Recommended Lighting Store</strong></p>
          <p><a href="http://www.light-express.com/">Light Express</a></p>
        </Col>
        <Col lg={7}>
          <p>
          <StaticImage
            src="../images/electrical-panel.jpg"
            quality={95}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Electrical Panel"
          />
          </p>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default SecondPage
